<template>
  <div class="top-bar-wraper">
    <div class="counter">
      <pil-counter :tab="tab" />
    </div>
    <slot name="nav-buttons"></slot>
    <div class="user-wraper">
      <pil-user />
    </div>
  </div>
</template>

<script>
import PilUser from "@/components/topBar/User";
import PilCounter from "@/components/catalog/counter.vue";

export default {
  name: "PilTopBarCatalog",
  props: ["tab"],
  components: {
    PilUser,
    PilCounter
  }
};
</script>

<style scoped>
.top-bar-wraper {
  display: flex;
  justify-content: space-between;
  height: 80px;
}
.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 180px;
  cursor: pointer;
}
.user-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 180px;
}
@media screen and (max-width: 990px) {
  .top-bar-wraper {
    height: 65px;
  }
  .user-wraper,
  .counter {
    width: 100px;
  }
}
</style>
