<template>
  <div class="container">
    <div class="btns-list">
      <button
        class="popup-btn"
        v-show="checkedItems.length"
        @click="showPopup('deleteItemPopup')"
      >
        delete
      </button>
      <button
        class="popup-btn"
        v-show="checkedItems.length && showChangeStatusBtn"
        @click="showPopup('changeStatus')"
      >
        change status
      </button>
      <template v-if="isAllowedImportExport">
        <button class="popup-btn" @click="showPopup('importPopup')">
          import table
        </button>
        <button class="popup-btn" @click="onExportData">
          export table
        </button>
      </template>
      <button
        v-show="showAddBtn"
        class="popup-btn"
        @click="showPopup('addPopup')"
      >
        add new
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AuthService from "@/services/AuthService";

export default {
  name: "PilCatalogOptionsButtons",
  props: ["tab"],
  data() {
    return {
      tabsToTablesName: {
        templates: "template",
        images: "rich_image",
        texts: "text",
        fonts: "font",
        categories: "category",
        users: "user",
        // pages: "pages"
      }
    }
  },
  computed: {
    ...mapGetters({
      device: "workSpace/device",
      checkedItems: "catalog/checkedItems"
    }),
    isAllowedImportExport() {
      return Object.keys(this.tabsToTablesName).includes(this.tab)
    },
    showAddBtn() {
      return (
        this.tab === "images" ||
        this.tab === "fonts" ||
        this.tab === "categories"
      );
    },
    showChangeStatusBtn() {
      return (
        this.tab === "templates" ||
        this.tab === "images" ||
        this.tab === "fonts" ||
        this.tab === "texts" ||
        this.tab === "categories"
      );
    }
  },
  methods: {
    showPopup(type) {
      this.$store.dispatch("workSpace/showPopup", {
        type,
        data: { items: this.checkedItems, step: this.tab }
      });
    },
    async onExportData() {
      await AuthService.exportData({
        table: this.tabsToTablesName[this.tab]
      });
    }
  }
};
</script>

<style scoped>
.container {
  display: grid;
  justify-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 35px;
}
.btns-list {
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
}
.popup-btn {
  margin: 0 10px 10px;
  white-space: nowrap;
}
@media screen and (max-width: 990px) {
  .popup-btn {
    min-width: fit-content;
    box-sizing: content-box;
    margin: 0 10px 10px;
  }
}
</style>
