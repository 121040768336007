<template>
  <div class="ctl-items-counter" v-if="tab !== 'analytics'">
    <span class="ctl-items-count">{{ counterMod }}</span>
    <span class="ctl-items-title">{{ tab }}</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PilCatalogCounter",
  props: ["tab"],
  computed: {
    ...mapGetters({
      counter: "catalog/counter"
    }),
    counterMod() {
      let prop = this.tab;
      if (this.tab === "images") prop = "richImages";
      return this.counter[prop] ?? 0;
    }
  }
};
</script>
