<template>
  <div class="user-wraper">
    <div class="center-column">
      <div @click="visibleUserMenu = !visibleUserMenu" class="center-column">
        <img alt="img" class="user-logo" :src="userLogo" />
        <span class="user-name">{{ name || userEmail }}</span>
      </div>
      <div
        v-show="visibleUserMenu || device === 'desktop'"
        :class="device === 'mobile' ? 'overlay' : 'relative'"
        :style="overlayHeight"
        @click="onHideUserMenu"
      >
        <div :class="device === 'mobile' ? 'overlay-card' : 'absolute'">
          <div
            @click.stop="clearActiveObjects"
            v-if="!isCatalogEditor"
            class="scroll"
          >
            <div class="user-logo-wraper">
              <img alt="img" class="user-logo-card" :src="userLogo" />
            </div>
            <span v-show="name" class="user-name popup">{{ name }}</span>
            <span class="user-email">{{ userEmail }}</span>
            <div
              class="settings"
              style="margin-bottom: 0;"
              @click="
                $store.dispatch('workSpace/showPopup', {
                  type: 'dashboardPopup'
                })
              "
            >
              responds
            </div>
            <div
              class="settings"
              style="margin-bottom: 0;"
              @click="
                $store.dispatch('workSpace/showPopup', {
                  type: 'contributionsPopup'
                })
              "
            >
              my contributions
            </div>
            <div
              class="settings"
              @click="
                $store.dispatch('workSpace/showPopup', {
                  type: 'userSettings'
                })
              "
            >
              settings
            </div>
            <router-link
              tag="div"
              @click.native="onMoveToCatalogEditor"
              to="/catalog_editor/templates"
              v-if="currentUser && currentUser.role === 'editor'"
              class="settings editor"
            >
              catalog editor
            </router-link>
            <div class="block" v-if="device === 'mobile'">
              <div
                class="feedback footer-item"
                @click="
                  $store.dispatch('workSpace/showPopup', {
                    type: 'feedback'
                  })
                "
              >
                feedback
              </div>
            </div>
            <div class="sign-out-btn" @click.stop="logout">
              sign out
            </div>
            <div
              class="terms-and-contions"
              @click="
                $store.dispatch('workSpace/showPopup', {
                  type: 'termsAndConditions'
                })
              "
            >
              terms & conditions
            </div>
          </div>
          <div class="scroll" v-if="isCatalogEditor">
            <div class="user-logo-wraper">
              <img alt="img" class="user-logo-card" :src="userLogo" />
            </div>
            <span v-show="name" class="user-name popup">{{ name }}</span>
            <span class="user-email">{{ userEmail }}</span>
            <router-link
              tag="div"
              to="/"
              v-if="currentUser && currentUser.role === 'editor'"
              class="settings editor"
            >
              main
            </router-link>
            <div class="sign-out-btn" @click.stop="logout">
              sign out
            </div>
          </div>
          <!-- close -->
          <div class="close" v-show="device === 'mobile'">&times;</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "user",
  data() {
    return {
      visibleUserMenu: false,
      visiblePopover: false
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      isLoggedIn: "auth/isLoggedIn",
      windowHeight: "workSpace/windowHeight",
      device: "workSpace/device",
      canvas: "workSpace/canvas",
      survey: "workSpace/survey",
      interactionMode: "workSpace/interactionMode"
    }),
    _canvas() {
      return this.interactionMode ? this.survey : this.canvas;
    },
    placement() {
      return "top";
    },
    userEmail() {
      return this.currentUser?.email;
    },
    name() {
      return this.currentUser?.name;
    },
    overlayHeight() {
      return this.device === "mobile"
        ? { height: this.windowHeight - 1 + "px" }
        : null;
    },
    isCatalogEditor() {
      return this.$route.meta?.layout === "catalogeditor";
    },
    userLogo() {
      return (
        this.currentUser?.avatar || require("@/assets/img/defaultUser.png")
      );
    }
  },
  methods: {
    clearActiveObjects() {
      this._canvas.discardActiveObject().renderAll();
    },
    onMoveToCatalogEditor() {
      this.$store.dispatch("workSpace/setDefaultCanvasWorkSpace");
      this.$store.dispatch("workSpace/setDefaultSurveyWorkSpace");
      this._canvas.discardActiveObject().renderAll();
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        if (this.isCatalogEditor) this.$router.replace("/");
      });
    },
    onHideUserMenu() {
      this.visibleUserMenu = !this.visibleUserMenu;
    }
  }
};
</script>

<style scoped>
.user-wraper {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
}
.user-logo {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.user-name {
  font-size: 12px;
  font-weight: 600;
}
.center-column:hover .relative {
  display: flex;
  opacity: 1;
  z-index: 10;
}
.relative {
  position: relative;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.1s;
}
.absolute {
  position: absolute;
  z-index: 5;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 180px;
  min-height: 140px;
  background: #fff;
  box-shadow: 0px 2px 6px #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}
.user-logo-wraper {
  display: flex;
}
.user-logo-card {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-top: 10px;
}
.user-name {
  font-size: 12px;
  font-weight: 600;
}
.user-email {
  font-size: 8px;
  line-height: 8px;
}
.settings {
  margin: 10px 0 8px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}
.terms-and-contions {
  margin: 8px 0;
  font-size: 8px;
  text-align: center;
  cursor: pointer;
}
.sign-out-btn {
  min-width: 116px;
  height: 28px;
  border: 1px solid #1d1d1d;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  line-height: 28px;
  text-align: center;
}
.editor {
  color: red;
  margin-top: 0;
}
.overlay {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(188, 188, 203, 0.8);
  z-index: 5;
  cursor: auto;
}
.block {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
  cursor: pointer;
}
.scroll {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 990px) {
  .close {
    position: absolute;
    right: 18px;
    top: 18px;
    color: #1d2d36;
    font-size: 30px;
    line-height: 10px;
    cursor: pointer;
  }
  .overlay-card {
    position: relative;
    z-index: 6;
    width: fit-content;
    padding: 0 20px;
    min-height: fit-content;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    box-sizing: border-box;
    width: 350px;
    max-width: calc(100vw - 20px);
    height: 600px;
    max-height: calc(100vh - 120px);
  }
  .user-logo-card {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
  }
  .user-name.popup {
    max-width: 300px;
    font-size: 16px;
    min-height: 16px;
    line-height: 1.2;
  }
  .user-email {
    font-size: 18px;
    line-height: 1.2;
  }
  .feedback,
  .settings {
    text-align: center;
    font-size: 26px;
  }
  .terms-and-contions {
    font-size: 16px;
  }
  .sign-out-btn {
    min-width: 140px;
    height: 30px;
    font-size: 16px;
  }
  .feedback {
    margin-bottom: 10px;
  }
  .user-name {
    font-size: 8px;
    white-space: nowrap;
    max-width: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .user-logo {
    max-width: 24px;
    max-height: 24px;
    width: 100%;
  }
}
@media screen and (max-height: 500px) {
  .overlay-card {
    height: fit-content;
    justify-content: flex-start;
  }
  .scroll {
    overflow: auto;
    padding: 20px 0;
  }
  .user-name.popup {
    font-size: 4vmin;
    min-height: 5vmin;
  }
  .user-email {
    font-size: 2.5vmin;
    line-height: 2.5vmin;
  }
  .feedback,
  .settings {
    font-size: 4vmin;
    margin: 2.5vmin 0 0;
  }
  .feedback {
    margin-top: 2.5vmin;
  }
  .block {
    font-size: 4vmin;
    margin-bottom: 2.5vmin;
  }
  .terms-and-contions {
    margin: 2vmin 0;
    font-size: 3vmin;
  }
  .sign-out-btn {
    min-width: 20vmin;
    height: 5vmin;
    border-radius: 1.5vmin;
    font-size: 3vmin;
    line-height: 5vmin;
  }
  .user-logo-card {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
  }
}
</style>
