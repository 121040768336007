var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-wraper"},[_c('div',{staticClass:"center-column"},[_c('div',{staticClass:"center-column",on:{"click":function($event){_vm.visibleUserMenu = !_vm.visibleUserMenu}}},[_c('img',{staticClass:"user-logo",attrs:{"alt":"img","src":_vm.userLogo}}),_c('span',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.name || _vm.userEmail))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visibleUserMenu || _vm.device === 'desktop'),expression:"visibleUserMenu || device === 'desktop'"}],class:_vm.device === 'mobile' ? 'overlay' : 'relative',style:(_vm.overlayHeight),on:{"click":_vm.onHideUserMenu}},[_c('div',{class:_vm.device === 'mobile' ? 'overlay-card' : 'absolute'},[(!_vm.isCatalogEditor)?_c('div',{staticClass:"scroll",on:{"click":function($event){$event.stopPropagation();return _vm.clearActiveObjects.apply(null, arguments)}}},[_c('div',{staticClass:"user-logo-wraper"},[_c('img',{staticClass:"user-logo-card",attrs:{"alt":"img","src":_vm.userLogo}})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.name),expression:"name"}],staticClass:"user-name popup"},[_vm._v(_vm._s(_vm.name))]),_c('span',{staticClass:"user-email"},[_vm._v(_vm._s(_vm.userEmail))]),_c('div',{staticClass:"settings",staticStyle:{"margin-bottom":"0"},on:{"click":function($event){return _vm.$store.dispatch('workSpace/showPopup', {
                type: 'dashboardPopup'
              })}}},[_vm._v(" responds ")]),_c('div',{staticClass:"settings",staticStyle:{"margin-bottom":"0"},on:{"click":function($event){return _vm.$store.dispatch('workSpace/showPopup', {
                type: 'contributionsPopup'
              })}}},[_vm._v(" my contributions ")]),_c('div',{staticClass:"settings",on:{"click":function($event){return _vm.$store.dispatch('workSpace/showPopup', {
                type: 'userSettings'
              })}}},[_vm._v(" settings ")]),(_vm.currentUser && _vm.currentUser.role === 'editor')?_c('router-link',{staticClass:"settings editor",attrs:{"tag":"div","to":"/catalog_editor/templates"},nativeOn:{"click":function($event){return _vm.onMoveToCatalogEditor.apply(null, arguments)}}},[_vm._v(" catalog editor ")]):_vm._e(),(_vm.device === 'mobile')?_c('div',{staticClass:"block"},[_c('div',{staticClass:"feedback footer-item",on:{"click":function($event){return _vm.$store.dispatch('workSpace/showPopup', {
                  type: 'feedback'
                })}}},[_vm._v(" feedback ")])]):_vm._e(),_c('div',{staticClass:"sign-out-btn",on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_vm._v(" sign out ")]),_c('div',{staticClass:"terms-and-contions",on:{"click":function($event){return _vm.$store.dispatch('workSpace/showPopup', {
                type: 'termsAndConditions'
              })}}},[_vm._v(" terms & conditions ")])],1):_vm._e(),(_vm.isCatalogEditor)?_c('div',{staticClass:"scroll"},[_c('div',{staticClass:"user-logo-wraper"},[_c('img',{staticClass:"user-logo-card",attrs:{"alt":"img","src":_vm.userLogo}})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.name),expression:"name"}],staticClass:"user-name popup"},[_vm._v(_vm._s(_vm.name))]),_c('span',{staticClass:"user-email"},[_vm._v(_vm._s(_vm.userEmail))]),(_vm.currentUser && _vm.currentUser.role === 'editor')?_c('router-link',{staticClass:"settings editor",attrs:{"tag":"div","to":"/"}},[_vm._v(" main ")]):_vm._e(),_c('div',{staticClass:"sign-out-btn",on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_vm._v(" sign out ")])],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.device === 'mobile'),expression:"device === 'mobile'"}],staticClass:"close"},[_vm._v("×")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }