<template>
  <div id="catalog-editor-layout">
    <topBar :tab="tab">
      <div slot="nav-buttons" class="ctl-navigation-contaner">
        <div
          class="ctl-nav-item"
          v-for="(t, idx) of tabs"
          :key="idx"
          :class="{ 'ctl-active-tab': tab === t.origin }"
          @click="tab = t.origin"
        >
          <img alt="img" :src="t.src" />
          <span class="ctl-nav-span">{{ t.name }}</span>
        </div>
      </div>
    </topBar>
    <pil-options-buttons :tab="tab" />
    <components class="ctl-tab-container" :tab="tab" :is="tab" />
    <preloader class="ctl-preloader" :visible="isLoadingContent" />
    <!-- <footerBtns :tab="tab" /> -->
    <components
      :is="popup"
      v-show="popupsVisible"
      @hide-modal="$store.dispatch('workSpace/showPopup', { clearAll: true })"
    />
  </div>
</template>

<script>
import topBar from "@/components/catalog/topBar";
// import footerBtns from "@/components/catalog/footerBtns";
import { mapGetters } from "vuex";
import PilOptionsButtons from "@/components/catalog/OptionsButtons";
import "@/assets/css/catalog.css";
import preloader from "@/components/preloaderMenu";

export default {
  name: "PilCatalogEditorLayout",
  components: {
    topBar,
    preloader,
    PilOptionsButtons,
    // footerBtns,
    templates: () =>
      import(
        /* webpackChunkName: "catalog tab templates"*/ "@/components/catalog/templates"
      ),
    pages: () =>
      import(
        /* webpackChunkName: "catalog tab pages"*/ "@/components/catalog/pages"
      ),
    images: () =>
      import(
        /* webpackChunkName: "catalog tab images"*/ "@/components/catalog/images"
      ),
    texts: () =>
      import(
        /* webpackChunkName: "catalog tab texts texts"*/ "@/components/catalog/texts"
      ),
    fonts: () =>
      import(
        /* webpackChunkName: "catalog tab fonts"*/ "@/components/catalog/fonts"
      ),
    categories: () =>
      import(
        /* webpackChunkName: "catalog tab categories"*/ "@/components/catalog/categories"
      ),
    interactions: () =>
      import(
        /* webpackChunkName: "catalog tab interactions"*/ "@/components/catalog/interactions"
      ),
    users: () =>
      import(
        /* webpackChunkName: "catalog tab users"*/ "@/components/catalog/users"
      ),
    analytics: () =>
      import(
        /* webpackChunkName: "catalog tab analytics"*/ "@/components/catalog/analytics"
      ),
    feedbacks: () =>
      import(
        /* webpackChunkName: "catalog tab feedbacks"*/ "@/components/catalog/feedbacks"
      ),
    // ? popups
    addPopup: () =>
      import(
        /* webpackChunkName: "catalog add popup"*/ "@/components/catalog/popups/addPopup"
      ),
    editPopup: () =>
      import(
        /* webpackChunkName: "catalog delete item popup"*/ "@/components/catalog/popups/editPopup"
      ),
    deleteItemPopup: () =>
      import(
        /* webpackChunkName: "catalogTab"*/ "@/components/catalog/popups/deleteItemPopup"
      ),
    importPopup: () =>
      import(
        /* webpackChunkName: "catalog import popup"*/ "@/components/catalog/popups/importPopup"
      ),
    changeStatus: () =>
      import(
        /* webpackChunkName: "catalog change status popup"*/ "@/components/catalog/popups/changeStatus"
      )
  },
  computed: {
    ...mapGetters({
      isLoadingContent: "catalog/isLoadingContent",
      popupsVisible: "workSpace/popupsVisible",
      popupsType: "workSpace/popupsType",
      counter: "catalog/counter"
    }),
    popup() {
      return this.popupsType || "div";
    }
  },
  data() {
    return {
      tab: "templates",
      tabs: [
        {
          src: require("@/assets/img/tabs/templates.svg"),
          name: "templates",
          origin: "templates"
        },
        {
          src: require("@/assets/img/tabs/pages.svg"),
          name: "pages",
          origin: "pages"
        },
        {
          src: require("@/assets/img/tabs/images.svg"),
          name: "images",
          origin: "images"
        },
        {
          src: require("@/assets/img/tabs/text.svg"),
          name: "texts",
          origin: "texts"
        },
        {
          src: require("@/assets/img/tabs/font.svg"),
          name: "fonts",
          origin: "fonts"
        },
        {
          src: require("@/assets/img/tabs/elements.svg"),
          name: "categories",
          origin: "categories"
        },
        {
          src: require("@/assets/img/tabs/interactions.svg"),
          name: "interactions",
          origin: "interactions"
        },
        // {
        //   src: require("@/assets/img/tabs/languages.svg"),
        //   name: "languages",
        // },
        // {
        //   src: require("@/assets/img/tabs/translation.svg"),
        //   name: "translations",
        // },
        {
          src: require("@/assets/img/tabs/users.svg"),
          name: "users",
          origin: "users"
        },
        {
          src: require("@/assets/img/tabs/analytics.svg"),
          name: "analytics",
          origin: "analytics"
        },
        {
          src: require("@/assets/img/like.png"),
          name: "feedbacks",
          origin: "feedbacks"
        }
      ]
    };
  },
  mounted() {
    if (this.$route.params.tab) {
      const isCorrectTab = this.tabs.find(
        t => t.name === this.$route.params.tab
      );
      if (!isCorrectTab) this.$router.push("./templates");
      else this.tab = this.$route.params.tab;
    }
  },
  watch: {
    tab(value, oldValue) {
      if (oldValue !== value && this.$route.params.tab !== value) {
        this.$router.push(`./${value}`);
        this.$store.commit("catalog/updateCheckedItems");
      }
    }
  }
};
</script>
